import React from 'react';
import { object } from 'prop-types';
import { allComponents, SEO } from '../components';
import website from '../../config/website';
import { withPreview } from 'gatsby-source-prismic';

import {
  ArticleDetail,
  PreviewOfNextStory,
  ArticleDetailRender,
  RelatedStoriesCarouselRm002
} from 'components';

const getTitle = ({ title }) => {
  if (title) {
    return title.text + ' | ' + website._title;
  } else {
    return website._title;
  }
};

const ArticleConstructor = ({ pageContext, location, data }) => {
  if (data && data[Object.keys(data)[0]]) {
    let newData = data[Object.keys(data)[0]];
    let newArr = [];
    // Preview data is loaded via proxy and we need to loop through it to get it loaded
    newData.data.body.forEach(element => {
      newArr.push(element);
    });
    pageContext.names = newArr; // Inject it back into the main component array
    pageContext.pageLang = data.data.pageLang;
    pageContext.title = newData.data.meta_title;
    pageContext.description = newData.data.meta_description;
    pageContext.robots = newData.data.robots;
    pageContext.articleTags = newData.data.articleTags;
    pageContext.newsletter = newData.data.newsletter;
    pageContext.faq_link = newData.data.faq_link;
  }

  const next = pageContext.next !== null && pageContext.next;

  let VideModule = null;
  let videoData = null;

  pageContext.names.forEach(item => {
    if (item.__typename === 'PrismicStoryBodyFullWidthVideoEm014') {
      videoData = item;
      VideModule = allComponents['Em014'];
    }
  });

  const restModules = pageContext.names.filter(
    el =>
      el.__typename !== 'PrismicStoryBodyFullWidthVideoEm014' &&
      el.__typename !== 'PrismicStoryBody3XRelatedStoriesCarouselRm002'
  );
  const relatedCarousel = pageContext.names.filter(
    el => el.__typename === 'PrismicStoryBody3XRelatedStoriesCarouselRm002'
  );

  return (
    <ArticleDetailRender locationKey={location.key} pageLang={pageContext.pageLang} newsletter={pageContext.newsletter}>
      <SEO
        title={`${getTitle(pageContext)}`}
        robots={pageContext.robots ? pageContext.robots : null}
        description={pageContext.description ? pageContext.description : null}
      />
      <ArticleDetail
        pageLang={pageContext.pageLang}
        articleDate={pageContext}
        videoModule={() =>
          VideModule ? (
            <VideModule
              primary={videoData.primary}
              uid={pageContext.uid}
              pageLang={pageContext.pageLang}
            />
          ) : null
        }
        next={() =>
          next && next.data.lister_title && next.data.lister_image ? (
            <PreviewOfNextStory
              uid={next.uid}
              locationKey={location.key}
              title={next.data.lister_title}
              image={next.data.lister_image}
              pageLang={pageContext.pageLang}
            />
          ) : null
        }
        carousel={() =>
          relatedCarousel.length !== 0 ? (
            <RelatedStoriesCarouselRm002
              primary={relatedCarousel[0].primary}
              items={relatedCarousel[0].items}
              type={pageContext.type}
              pageLang={pageContext.pageLang}
            />
          ) : null
        }
      >
        {restModules.map((el, i) => {
          const postfix = el ? el.__typename.slice(-5) : null;
          const Component = allComponents[postfix];
          return !Component ? null : (
            <Component
              key={i}
              primary={el.primary}
              items={el.items}
              pageLang={pageContext.pageLang}
            />
          );
        })}
      </ArticleDetail>
    </ArticleDetailRender>
  );
};

ArticleConstructor.propTypes = {
  pageContext: object.isRequired,
  location: object.isRequired
};

export default withPreview(ArticleConstructor);
